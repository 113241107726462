import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Self-Taught in C#/Unity, TypeScript/React. `}</p>
    </blockquote>
    <p>{`I've been involved in community building around 2014-2015. Mostly as a hobby, but it's helped shape my skills and drive me to learn new technologies. The technologies we used were React, C# AspNetCore on the backend, MongoDB/MySQL for database, Redis for caching, and intergations with Sentry & DataDog. We used Cloudflare extensively to protect our websites & APIs, and also for Zero Trust. We ran Windows Servers to run actual game servers, and I was deeply involved in managing & protecting them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      